<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">差错退款单查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item label="申请日期">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.createTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="退款日期">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.repayTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="机票订单号">
              <a-input
                size="small"
                placeholder="订单号"
                v-decorator="[
                  'searchForm.orderNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="支付流水">
              <a-input
                size="small"
                placeholder="支付流水"
                v-decorator="[
                  'searchForm.tradeNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="申请单状态">
              <a-select
                size="small"
                style="width: 150px"
                v-decorator="[
                  'searchForm.status',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option
                  v-for="status in refundDepositStatus"
                  :key="status.value"
                  :value="status.value"
                  >{{ status.label }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="申请单号">
              <a-input
                size="small"
                placeholder="差错申请单号"
                v-decorator="[
                  'searchForm.misPayNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <!-- <a-form-item label="退款状态">
             <a-select
                size="small"
                style="width: 100px"
                default-value="0"
                v-model="statusType"
              >
                <a-select-option value="0">已退款</a-select-option>
                <a-select-option value="1">未退款</a-select-option>
              </a-select>
            </a-form-item> -->
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
              <!-- <a-button size="small"> 导出 </a-button> -->
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{ x: 'max-content' }"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            bordered
            :loading="loading"
            @change="onTableChange"
          >
            <template slot="index" slot-scope="index">
              {{ index }}
            </template>
            <template slot="misPayNo" slot-scope="misPayNo">
              <router-link
                :to="{
                  path: '/orderManager/differRefundDetail',
                  query: { misPayNo: misPayNo },
                }"
                >{{ misPayNo }}</router-link
              >
            </template>
            <template slot="status" slot-scope="status">
              {{ status | refundDepositStatusFilter }}
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'

import { refundDepositStatus } from '@/dicts/order'
import { bank } from '@/filters/pay'
import { refundDepositStatus as refundDepositStatusFilter } from '@/filters/order'

import { fetchDifferRefundList } from '@/api/order'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '差错申请单号',
    dataIndex: 'misPayNo',
    scopedSlots: { customRender: 'misPayNo' }
  },
  {
    title: '订单号',
    dataIndex: 'orderNo'
  },
  {
    title: '提交人',
    dataIndex: 'submitName'
  },
  {
    title: '申退时间',
    dataIndex: 'creationTimestamp'
  },
  {
    title: '申退金额',
    dataIndex: 'applyAmount'
  },
  {
    title: '审核退款金额',
    dataIndex: 'actualAmount'
  },
  {
    title: '退款日期',
    dataIndex: 'repayTime'
  },
  // {
  //   title: '退款金额',
  //   dataIndex: 'repayTime'
  // },
  {
    title: '申请单状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    // const segments = col.segmentInfos.map((seg) => {
    //   const { depCode, arrCode } = seg
    //   return { orgCity: depCode, dstCity: arrCode }
    // })

    return {
      key: col.id,
      index: page * size + (index + 1),
      ...col,
      ...{
        // segments: segments
      }
    }
  })
}

export default {
  name: 'differRefundSearch',
  components: {},
  mixins: [tableBaseMixin, orderSearchMixin],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      columns,
      refundDepositStatus,
      params: {
        misPayNo: '',
        orderNo: '',
        createTimeEnd: '',
        createTimeStart: '',
        repayTimeEnd: '',
        repayTimeStart: '',
        status: '',
        tradeNo: '',
        page: 0,
        size: 10
      }
    }
  },
  mounted () {
    this.fetchTableData()
  },
  methods: {
    async fetchTableData () {
      this.loading = true
      try {
        const result = await fetchDifferRefundList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm

      const params = {
        orderNo: fields.orderNo,
        misPayNo: fields.misPayNo,
        status: fields.status,
        tradeNo: fields.tradeNo
      }

      if (fields.createTime) {
        params.createTimeStart = fields.createTime[0].format(
          'YYYY-MM-DD 00:00:00'
        )
        params.createTimeEnd = fields.createTime[1].format(
          'YYYY-MM-DD 23:59:59'
        )
      }
      if (fields.repayTime) {
        params.repayTimeStart = fields.repayTime[0].format(
          'YYYY-MM-DD 00:00:00'
        )
        params.repayTimeEnd = fields.repayTime[1].format('YYYY-MM-DD 23:59:59')
      }

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchTableData()
    },
    handleFieldValidate (rule, value, callback) {
      // validate(
      //   'search-order',
      //   this.bookForm.getFieldsValue(),
      //   rule.fullField
      // ).then(({ valid, error }) => {
      //   if (valid) {
      //     callback()
      //   } else {
      //     callback(error)
      //   }
      // })
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchTableData()
    },
    onReset () {
      this.searchForm.resetFields()
    }
  },
  filters: {
    refundDepositStatusFilter,
    bank
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
